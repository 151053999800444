import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const HomeLayout = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(()=>{
        if(location.pathname === '/') {
            navigate("/login");
            localStorage.removeItem('token');
          }
    },[]);
    
        
    
        return (
            <>
        <div>
            <Outlet/> 
            </div>
            </>
    )
}

export default HomeLayout