import React, { useContext, useState } from "react";
import sidebarData from "./sidebarData.json";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const Sidebar = ({ sideOpen, mdopen }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const location = useLocation();
  const { permission } = useContext(AuthContext);

  const handleClick = (index, len) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close if clicked again
    } else {
      setActiveIndex(index); // Open the clicked item
    }

    if (index === 0) mdopen();
  };

  return (
    <div className={sideOpen ? "slide-nav" : ""}>
      <div className="sidebar bg-white" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div
            id="sidebar-menu"
            className="sidebar-menu"
            style={{ width: "260px" }}
          >
            <ul>
              {sidebarData.map((item, index) => (
                <li key={item.id} className="submenu">
                  <Link
                    to={item.link}
                    className={activeIndex === index ? "active" : ""}
                    onClick={() => handleClick(index, item.list.length)}
                  >
                    <img src={item.img} alt="img" />
                    <span> {item.title}</span>
                    {item.list.length !== 0 && (
                      <span className="menu-arrow"></span>
                    )}
                  </Link>
                  <ul
                    style={{
                      display:
                        activeIndex === index && item.list.length !== 0
                          ? "block"
                          : "none",
                    }}
                  >
                    {item.list.length !== 0 &&
                      item.list.map((element) => {
                        let hasPermission = false;
                        if (element.action === "view")
                          hasPermission = permission[element.val].view;
                        else hasPermission = permission[element.val].create;
                        return (
                          hasPermission && (
                            <li key={element.link}>
                              <Link
                                className={
                                  location.pathname === element.link
                                    ? "active"
                                    : ""
                                }
                                onClick={mdopen}
                                to={element.link}
                              >
                                {element.title}
                              </Link>
                            </li>
                          )
                        );
                      })}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

// {
//     "id": 4,
//     "title":"Report",
//     "img":"/img/icons/time.svg",
//     "link":"#",
//      "list":[
//         {
//             "title": "Purchase order report",
//             "link": "/purchaseorderreport"
//         },
//         {
//             "title": "Inventory Report",
//             "link": "/inventoryreport"
//         },
//         {
//             "title": "Sales Report",
//             "link": "/salesreport"
//         },
//         {
//             "title": "Invoice Report",
//             "link": "/invoicereport"
//         },
//         {
//             "title": "Supplier Report",
//             "link": "/supplierreport"
//         },
//         {
//             "title": "Customer Report",
//             "link": "/customerreport"
//         }
//     ]

//  },
