import logo from "./logo.svg";
import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import 'jspdf-autotable'
import Login from "./Components/Login/Login";
import { AuthProvider } from "./Components/Context/AuthContext";
// import Dashboard from './Components/Dashboard/Dashboard';
import PrivateLayout from "./Components/Layout/PrivateLayout";
import AddStock from "./Components/Stock/AddStock";
import CategoryList from "./Components/Stock/CategoryList";
import AddCategory from "./Components/Stock/AddCategory";
import AddPurchase from "./Components/Purchase/CreatePurchase";
import ImportPurchase from "./Components/Purchase/OldDataUpload";
import PaymentList from "./Components/Payment/PaymentList";
import AddPayment from "./Components/Payment/AddPayment";
import PurchaseOrderReport from "./Components/Report/PurchaseOderReport";
import InventoryReport from "./Components/Stock/InventoryReport";
import InvoiceReport from "./Components/Report/InvoiceReport";
import PurchaseReport from "./Components/Purchase/PurchaseReport";
import VendorReport from "./Components/Report/VendorReport";
import CustomerReport from "./Components/Report/CustomerReport";
import UserList from "./Components/User/UserList";
import RolesAndPermission from "./Components/User/RolesAndPermission";
import GeneralSettings from "./Components/Settings/Configurations";
import Navbar from "./Components/Navbar/Navbar";
import Dashboard from "./Components/Dashboard/Dashboard";
import BillUpload from "./Components/Purchase/BillUpload";
import StockAddition from "./Components/Purchase/StockAddition";
import LevelManagement from "./Components/Stock/LevelManagement";
import CreateVendor from "./Components/Settings/CreateVendor";
import CreateItem from "./Components/Stock/CreateItem";
import PaymentReport from "./Components/Payment/PaymentReport";
import OutstandingPayment from "./Components/Payment/OutstandingPayment";
import HomeLayout from "./Components/Layout/HomeLayout";
import AllNotifications from "./Components/Navbar/AllNotifications";
import ForgotPassword from "./Components/Login/ForgotPassword";
import Permissions from "./Components/User/Permissions";
import { ItemProvider } from "./Components/Context/ItemContext";
import { CategoryProvider } from "./Components/Context/CategoryContext";
import { VendorProvider } from "./Components/Context/VendorContext";
import { PurchaseProvider } from "./Components/Context/PurchaseContext";
import AddSales from "./Components/Sales/AddSales";
import SalesReport from "./Components/Sales/SalesReport";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      <ToastContainer/>
      <AuthProvider>
        <ItemProvider>
          <CategoryProvider>
            <VendorProvider>
              <PurchaseProvider>
                <Routes>
                  <Route path="/" element={<HomeLayout />}>
                    <Route index path="/login" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                  </Route>

                  <Route element={<PrivateLayout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/stock/item-ledger" element={<CreateItem />} />
                    <Route path="/addstock" element={<AddStock />} />
                    <Route
                      path="/stock/level-management"
                      element={<LevelManagement />}
                    />
                    <Route
                      path="/stock/item-category"
                      element={<CategoryList />}
                    />
                    <Route path="/addcategory" element={<AddCategory />} />
                    <Route
                      path="/purchase/stock-addition"
                      element={<StockAddition />}
                    />
                    <Route path="/purchase/create" element={<AddPurchase />} />
                    <Route
                      path="/purchase/old-data-upload"
                      element={<ImportPurchase />}
                    />
                    <Route
                      path="/purchase/bill-upload"
                      element={<BillUpload />}
                    />
                    <Route
                      path="/purchase/reports"
                      element={<PurchaseReport />}
                    />
                    <Route path="/payments/summary" element={<PaymentList />} />
                    <Route path="/payments/entry" element={<AddPayment />} />
                    <Route
                      path="/payments/outstanding"
                      element={<OutstandingPayment />}
                    />
                    <Route
                      path="/payments/reports"
                      element={<PaymentReport />}
                    />
                    <Route
                      path="/purchaseorderreport"
                      element={<PurchaseOrderReport />}
                    />
                    <Route
                      path="/stock/reports"
                      element={<InventoryReport />}
                    />
                    <Route path="/salesreport" element={<SalesReport />} />
                    <Route path="/invoicereport" element={<InvoiceReport />} />
                    <Route path="/supplierreport" element={<VendorReport />} />
                    <Route
                      path="/customerreport"
                      element={<CustomerReport />}
                    />
                   
                    <Route path="/users/list" element={<UserList />} />
                    <Route
                      path="/users/role-permission-create"
                      element={<RolesAndPermission />}
                    />
                    <Route path="/sales/entry" element={<AddSales />} />
                    <Route path="/sales/reports" element={<SalesReport />} />
                    <Route
                      path="/settings/configuration"
                      element={<GeneralSettings />}
                    />
                    <Route path="/vendor/create" element={<CreateVendor />} />
                    <Route path="/item/create" element={<CreateItem />} />
                    <Route
                      path="/all-notifications"
                      element={<AllNotifications />}
                    />
                    <Route path="/test" element={<Permissions />} />
                  </Route>
                </Routes>
              </PurchaseProvider>
            </VendorProvider>
          </CategoryProvider>
        </ItemProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
