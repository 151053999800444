import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Permissions from "./Permissions";
import axios from "axios";
import config from "../../Config";
import DataTable from "react-data-table-component";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const RolesAndPermission = () => {
  const { permission, logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [view, setViewShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [data, setData] = useState([]);
  const list = [
    "item",
    "category",
    "purchase",
    "payment",
    "vendor",
    "user",
    "sales",
    "roleAndPermission",
  ];
  const token = localStorage.getItem("token");
  const [roleData, setRoleData] = useState(null);
  const handleUpdate = async () => {
    try {
      if (roleData.role !== "" && roleData.description !== "") {
        const res = await axios.put(
          `${config.BASE_URL}/update-role/${roleData._id}`,
          roleData,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (res.status === 200) {
          toast.success("Role data updated!");
          setRoleData(null);
          setEditShow(false);
          fetchData();
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const [section, permission] = name.split(".");
      setRoleData((prevState) => ({
        ...prevState,
        permissions: {
          ...prevState.permissions,
          [section]: {
            ...prevState.permissions[section],
            [permission]: checked,
          },
        },
      }));
    } else {
      setRoleData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const tableHead = [
    {
      name: "Role",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <>
          <a onClick={() => handleViewClick(row)} className="me-3">
            <img src="/img/icons/view.svg" alt="img" />
          </a>
          {permission.roleAndPermission.edit && (
            <a onClick={() => handleEditClick(row)} className="me-3">
              <img src="/img/icons/edit.svg" alt="img" />
            </a>
          )}
          {permission.roleAndPermission.delete && (
            <a onClick={() => handleDelete(row._id)} className="confirm-text">
              <img src="/img/icons/delete.svg" alt="img" />
            </a>
          )}
        </>
      ),
      sortable: false,
    },
  ];
  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/getallroles`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setData(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  const handleEditClick = (row) => {
    setEditShow(true);
    setRoleData(row);
  };

  const handleViewClick = (row) => {
    setViewShow(true);
    setRoleData(row);
  };

  const handleDelete = async (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the Role?"
    );
    if (isConfirm) {
      try {
        console.log(id);
        const res = await axios.delete(`${config.BASE_URL}/delete-role/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (res.status === 200) {
          toast.success("Role Deleted!");
          fetchData();
        }
      } catch (error) {
        if (error.response.status === 401) {
          logout();
          toast.error("Your token has expired please login again!");
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Group Permissions</h4>
            <h6>Manage Group Permissions</h6>
          </div>
          {permission.roleAndPermission.create && (
            <div className="page-btn">
              <button
                type="button"
                onClick={() => setShow(true)}
                className="btn btn-added"
              >
                <img src="/img/icons/plus.svg" alt="img" className="me-1" /> Add
                Role
              </button>
            </div>
          )}
        </div>

        <div className="card">
          <div className="card-body">
            <DataTable columns={tableHead} data={data} pagination />
          </div>
        </div>
        <Modal show={show} onHide={() => setShow(false)} size="xl">
          <Permissions setShow={setShow} fetchData={fetchData} />
        </Modal>
        <Modal show={editShow} onHide={() => setEditShow(false)} size="xl">
          {roleData && (
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label>Role</label>
                      <input
                        type="text"
                        value={roleData.role}
                        onChange={handleChange}
                        name="role"
                      />
                    </div>
                  </div>
                  <div className="col-lg-9 col-sm-12">
                    <div className="form-group">
                      <label>Role Description</label>
                      <input
                        type="text"
                        value={roleData.description}
                        onChange={handleChange}
                        name="description"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="productdetails product-respon">
                      <ul>
                        {list.map((section, index) => (
                          <li key={index}>
                            <h4>{section}</h4>
                            <div className="input-checkset">
                              <ul>
                                {["view", "create", "edit", "delete"].map(
                                  (permission) => (
                                    <li key={permission}>
                                      <label className="inputcheck">
                                        {permission.charAt(0).toUpperCase() +
                                          permission.slice(1)}
                                        <input
                                          type="checkbox"
                                          name={`${section}.${permission}`}
                                          checked={
                                            roleData.permissions[section][
                                              permission
                                            ]
                                          }
                                          onChange={handleChange}
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-2 d-flex justify-content-end">
                    <a onClick={handleUpdate} className="btn btn-submit me-2">
                      Submit
                    </a>
                    <a
                      onClick={() => setEditShow(false)}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
        <Modal show={view} onHide={() => setViewShow(false)} size="xl">
          {roleData && (
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-sm-12">
                    <div className="form-group">
                      <label>Role</label>
                      <strong> {roleData.role} </strong>
                    </div>
                  </div>
                  <div className="col-lg-9 col-sm-12">
                    <div className="form-group">
                      <label>Role Description</label>
                      <strong> {roleData.description} </strong>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="productdetails product-respon">
                      <ul>
                        {list.map((section, index) => (
                          <li key={index}>
                            <h4>{section}</h4>
                            <div className="input-checkset">
                              <ul>
                                {["view", "create", "edit", "delete"].map(
                                  (permission) => (
                                    <li key={permission}>
                                      <label className="inputcheck">
                                        {permission.charAt(0).toUpperCase() +
                                          permission.slice(1)}
                                        {roleData.permissions[section][
                                          permission
                                        ] && (
                                          <input
                                            type="checkbox"
                                            name={`${section}.${permission}`}
                                            checked={
                                              roleData.permissions[section][
                                                permission
                                              ]
                                            }
                                          />
                                        )}

                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-12 mt-2 d-flex justify-content-end">
                    <a
                      onClick={() => setViewShow(false)}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default RolesAndPermission;
