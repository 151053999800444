import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ItemContext } from "../Context/ItemContext";
import { CategoryContext } from "../Context/CategoryContext";
import { AuthContext } from "../Context/AuthContext";

const CreateItem = () => {
  const [show, setShow] = useState(false);
  const {permission} = useContext(AuthContext);
  const [editShow, setEditShow] = useState(false);
  const [formData, setFormData] = useState({
    itemName: "",
    category: "",
    unitPrice: 0,
    stockQuantity: 0,
    gstRate: 0,
    description: "",
    minimumlevel: 0,
    maximumlevel: 0,
  });
  const { items, createItem, updateItem, deleteItem } = useContext(ItemContext);
  const { category } = useContext(CategoryContext);
  const tableHead = [
    {
      name: "Item Name",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Cetegory",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      sortable: true,
    },
    {
      name: "Stock Quantity",
      selector: (row) => row.stockQuantity,
      sortable: true,
    },
    {
      name: "GST Rate %",
      selector: (row) => row.gstRate,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: false,
    },
    ...(permission.item.edit || permission.item.delete ? [{
      name: "Action",
      selector: (row) => (
        <>
        {permission.item.edit && <a onClick={() => handleEditClick(row)} className="me-3">
            <img src="/img/icons/edit.svg" alt="img" />
          </a>}
        {permission.item.delete &&  <a onClick={() => handleDelete(row._id)} className="confirm-text">
            <img src="/img/icons/delete.svg" alt="img" />
          </a>}
        </>
      ),
      sortable: false,
    }]:[]),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the item?"
    );
    if (isConfirm) {
      try {
        console.log(id);
        deleteItem(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setEditShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleCreate = () => {
    try {
      createItem(formData);
      setFormData({
        itemName: "",
        category: "",
        unitPrice: 0,
        stockQuantity: 0,
        gstRate: 0,
        description: "",
        minimumlevel: 0,
        maximumlevel: 0,
      });
      setShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdate = (id) => {
    try {
      updateItem(id, formData);
      setFormData({
        itemName: "",
        category: "",
        unitPrice: 0,
        stockQuantity: 0,
        gstRate: 0,
        description: "",
        minimumlevel: 0,
        maximumlevel: 0,
      });
      setEditShow(false);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(()=>{
    console.log(items);
  },[])

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Item Ledger</h4>
            <h6>All Items</h6>
          </div>
        { permission.item.create && <div className="page-btn">
            <a
              onClick={() => {
                setShow(true);
                setFormData({
                  itemName: "",
                  category: "",
                  unitPrice: 0,
                  stockQuantity: 0,
                  gstRate: 0,
                  description: "",
                  minimumlevel: 0,
                  maximumlevel: 0,
                });
              }}
              className="btn btn-added"
            >
              <img src="/img/icons/plus.svg" alt="img" className="me-2" />{" "}
              Create Item
            </a>
          </div>}
        </div>

        <div className="card">
          <div className="card-body">
            <DataTable columns={tableHead} data={items} pagination />
          </div>
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Item Name</label>
                      <input
                        type="text"
                        name="itemName"
                        placeholder="Enter Item Name"
                        value={formData.itemName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label> Category </label>
                      <select
                        className="select"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value={""}>Select a Category</option>
                        {category &&
                          category.map((element) => (
                            <option
                              key={element._id}
                              value={element.categoryname}
                            >
                              {element.categoryname}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Unit Price</label>
                      <input
                        type="number"
                        name="unitPrice"
                        placeholder="Enter Unit Price"
                        value={formData.unitPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>GST Rate %</label>
                      <input
                        type="number"
                        name="gstRate"
                        placeholder="Enter GST Rate"
                        value={formData.gstRate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Stock Quantity</label>
                      <input
                        type="number"
                        name="stockQuantity"
                        placeholder="Enter Stock Quantity"
                        value={formData.stockQuantity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label> Minimum Level</label>
                      <input
                        type="number"
                        name="minimumlevel"
                        placeholder="Enter Minimum Level of Item"
                        value={formData.minimumlevel}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Maximum Level</label>
                      <input
                        type="number"
                        name="maximumlevel"
                        placeholder="Enter Maximum Level of Item"
                        value={formData.maximumlevel}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <div className="pass-group">
                        <textarea
                          type="text"
                          className="pass-inputs"
                          name="description"
                          placeholder="Enter Description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      className="btn btn-submit me-2"
                      onClick={handleCreate}
                    >
                      Submit
                    </button>
                    <button
                      onClick={() => {
                        setShow(false);
                        setFormData({
                          itemName: "",
                          category: "",
                          unitPrice: 0,
                          stockQuantity: 0,
                          gstRate: 0,
                          description: "",
                          minimumlevel: 0,
                          maximumlevel: 0,
                        });
                      }}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal size="lg" show={editShow} onHide={() => setEditShow(false)}>
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Item Name</label>
                      <input
                        type="text"
                        name="itemName"
                        placeholder="Enter Item Name"
                        value={formData.itemName}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label> Category </label>
                      <select
                        className="select"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value={""}>Select a Category</option>
                        {category &&
                          category.map((element) => (
                            <option
                              key={element._id}
                              value={element.categoryname}
                            >
                              {element.categoryname}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Unit Price</label>
                      <input
                        type="number"
                        name="unitPrice"
                        placeholder="Enter Unit Price"
                        value={formData.unitPrice}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>GST Rate %</label>
                      <input
                        type="number"
                        name="gstRate"
                        placeholder="Enter GST Rate"
                        value={formData.gstRate}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Stock Quantity</label>
                      <input
                        type="number"
                        name="stockQuantity"
                        placeholder="Enter Stock Quantity"
                        value={formData.stockQuantity}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <div className="pass-group">
                        <textarea
                          type="text"
                          className="pass-inputs"
                          name="description"
                          placeholder="Enter Description"
                          value={formData.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-end">
                    <button
                      className="btn btn-submit me-2"
                      onClick={() => handleUpdate(formData._id)}
                    >
                      Update
                    </button>
                    <button
                      onClick={() => {
                        setEditShow(false);
                        setFormData({
                          itemName: "",
                          category: "",
                          unitPrice: 0,
                          stockQuantity: 0,
                          gstRate: 0,
                          description: "",
                          minimumlevel: 0,
                          maximumlevel: 0,
                        });
                      }}
                      className="btn btn-cancel"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default CreateItem;
