import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ItemContext } from "../Context/ItemContext";
import { AuthContext } from "../Context/AuthContext";

const LevelManagement = () => {
  const [show, setShow] = useState(false);
  const { items, createItem, updateItem, deleteItem } = useContext(ItemContext);
  const [formData, setFormData] = useState(null);
  const {permission} = useContext(AuthContext);

  const tableHead = [
    {
      name: "Product Name",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Minium Level",
      selector: (row) => row.minimumlevel,
      sortable: true,
    },
    {
      name: "Maximum Level",
      selector: (row) => row.maximumlevel,
      sortable: true,
    },
    ...(permission.item.edit || permission.item.delete ? [{
      name: "Action",
      selector: (row) => (
        <>
          {permission.item.edit && <a onClick={() => handleEditClick(row)} className="me-3">
            <img src="/img/icons/edit.svg" alt="img" />
          </a>}
        { permission.item.delete && <a onClick={() => handleDelete(row._id)} className="confirm-text">
            <img src="/img/icons/delete.svg" alt="img" />
          </a>}
        </>
      ),
      sortable: false,
    }]:[]),
  ];

  const handleDelete = (id) => {
    const isConfirm = window.confirm(
      "Are you sure, you want to delete the item?"
    );
    if (isConfirm) {
      try {
        console.log(id);
        deleteItem(id);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleEditClick = (data) => {
    setFormData(data);
    setShow(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleUpdate = (id) => {
    try {
      updateItem(id, formData);
      setFormData(null);
      setShow(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Stock Level Management</h4>
            <h6>Add/Update the level of stock for Notification</h6>
          </div>
          {/* <div className="page-btn">
            <a onClick={() => setShow(true)} className="btn btn-added">
              <img src="/img/icons/plus.svg" alt="img" className="me-2" /> Add
              Item
            </a>
          </div> */}
        </div>
        <div className="card">
          <div className="card-body">
            <DataTable columns={tableHead} data={items} pagination />
          </div>
          <Modal size="lg" show={show} onHide={() => setShow(false)}>
            {formData && (
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12 col-sm-6 col-12">
                      <div className="form-group">{formData.itemName}</div>
                    </div>

                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Minimum Level</label>
                        <input
                          type="number"
                          value={formData.minimumlevel}
                          onChange={handleChange}
                          name="minimumlevel"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="form-group">
                        <label>Maximum Level</label>
                        <input
                          type="number"
                          value={formData.maximumlevel}
                          onChange={handleChange}
                          name="maximumlevel"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-end">
                    <div className="col-lg-12 d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => handleUpdate(formData._id)}
                        className="btn btn-submit me-2"
                      >
                        Submit
                      </button>
                      <button
                        onClick={() => {setShow(false); setFormData(null)}}
                        className="btn btn-cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default LevelManagement;
