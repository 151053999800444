import React from 'react'

const AllNotifications = () => {
  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>All Notifications</h4>
            <h6>View your all activities</h6>
          </div>
        </div>

        <div className="activity">
          <div className="activity-box">
            <ul className="activity-list">
              <li>
                <div className="activity-user">
                  <a href="profile.html" title="" data-toggle="tooltip" data-original-title="Lesley Grauer">
                    <img alt="Lesley Grauer" src="./img/customer/profile3.jpg" className="img-fluid" />
                  </a>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <a href="profile.html" className="name">Elwis Mathew </a> added a new product <a href="#">Redmi Pro 7 Mobile</a>
                    <span className="time">4 mins ago</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="activity-user">
                  <a href="profile.html" title="" data-toggle="tooltip" data-original-title="Lesley Grauer">
                    <img alt="Lesley Grauer" src="./img/customer/profile4.jpg" className="img-fluid" />
                  </a>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <a href="profile.html" className="name">Elizabeth Olsen</a> added a new product category <a href="#">Desktop Computers</a>
                    <span className="time">6 mins ago</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="activity-user">
                  <a href="profile.html" title="" data-toggle="tooltip" data-original-title="Lesley Grauer">
                    <img alt="Lesley Grauer" src="./img/customer/profile5.jpg" className="img-fluid" />
                  </a>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <a href="profile.html" className="name">William Smith</a> added a new sales list for<a href="#">January Month</a>
                    <span className="time">12 mins ago</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="activity-user">
                  <a href="profile.html" title="" data-toggle="tooltip" data-original-title="Lesley Grauer">
                    <img alt="Lesley Grauer" src="./img/customer/customer4.jpg" className="img-fluid" />
                  </a>
                </div>
                <div className="activity-content">
                  <div className="timeline-content">
                    <a href="profile.html" className="name">Lesley Grauer</a> has updated invoice <a href="#">#987654</a>
                    <span className="time">4 mins ago</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  )
}

export default AllNotifications