import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import config from "../../Config";
import { AuthContext } from "../Context/AuthContext";
import { toast } from "react-toastify";

const GeneralSettings = () => {
  const [settingsData, setSettingsData] = useState({
    applicationName: "",
    description: "",
    copyrightDetails: "",
    logo: null,
    favIcon: null,
  });
  const token = localStorage.getItem("token");
  const { logout } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "logo" || name === "favIcon") {
      const file = files[0];
      setSettingsData((prevdata) => ({
        ...prevdata,
        [name]: file,
      }));
    } else {
      setSettingsData((prevdata) => ({
        ...prevdata,
        [name]: value,
      }));
    }
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(`${config.BASE_URL}/all-settings`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        setSettingsData(res.data.data);
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUpdate = async () => {
    try {
      const formData = new FormData();
      formData.append("logo", settingsData.logo);
      formData.append("favIcon", settingsData.favIcon);
      formData.append(
        "applicationName",
        JSON.stringify(settingsData.applicationName)
      );
      formData.append("description", JSON.stringify(settingsData.description));
      formData.append(
        "copyrightDetails",
        JSON.stringify(settingsData.copyrightDetails)
      );

      const res = await axios.put(`${config.BASE_URL}/Settings`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status === 200) {
        toast.success("Settings Updated!");
      }
    } catch (error) {
      if (error.response.status === 401) {
        logout();
        toast.error("Your token has expired please login again!");
      }
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Configuration Setting</h4>
            <h6>Manage General Setting</h6>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              {/* <div className="col-lg-12 col-sm-6 col-12">
                <div className="form-group">
                  <label>Application Name </label>
                  <input
                    type="text"
                    placeholder="Enter Application Name"
                    value={settingsData.applicationName}
                    onChange={handleChange}
                    name="applicationName"
                  />
                </div>
              </div>

              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Description </label>
                  <textarea
                    type="text"
                    placeholder="Enter Description"
                    name="description"
                    value={settingsData.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Copyright Details </label>
                  <textarea
                    type="text"
                    placeholder="Enter Copyright Details"
                    name="copyrightDetails"
                    value={settingsData.copyrightDetails}
                    onChange={handleChange}
                  />
                </div>
              </div> */}

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Logo</label>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                      name="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>FavIcon</label>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleChange}
                      name="favIcon"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-submit me-2"
                    onClick={handleUpdate}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSettings;
