import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const Login = () => {
  const [formData, setFormData] = useState({email:'',password:''});
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevdata) => ({
      ...prevdata,
      [name]: value,
    }));
  };

  const handleSubmit = () =>{
    try {
      login(formData);
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div className="account-content">
      <div className="login-wrapper">
        <div className="login-content">
          <div className="login-userset">
            <div className="login-logo" style={{width:'100px',height:'90px'}}>
              <img src="/img/auth.png" alt="img" style={{height:'100%',width:'100%'}} />
            </div>
            <div className="login-userheading">
              <h3>Log In</h3>
              <h4>Please login to your account</h4>
            </div>
            <div className="form-login">
              <label>Email</label>
              <div className="form-addons">
                <input type="text" name="email" onChange={handleChange} value={formData.email} placeholder="Enter your Email" />
                <img src="/img/icons/mail.svg" alt="img" />
              </div>
            </div>
            <div className="form-login">
              <label>Password</label>
              <div className="pass-group">
                <input
                name="password"
                value={formData.password}
                onChange={handleChange}
                  type="password"
                  className="pass-input"
                  placeholder="Enter your password"
                />
                
              </div>
            </div>
            {/* <div className="form-login">
              <div className="alreadyuser">
                <h4>
                  <Link to="/forgot-password" className="hover-a">
                    Forgot Password?
                  </Link>
                </h4>
              </div>
            </div> */}
            <div className="form-login">
              <button type="button" className="btn btn-login" onClick={handleSubmit}>
                Log In
              </button>
            </div>
          </div>
        </div>
        <div className="login-img">
          <img src="/img/login.jpg" alt="img" />
        </div>
      </div>
    </div>
  );
};

export default Login;
