import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../Config";
import { toast } from "react-toastify";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [role, setRole] = useState([]);
  const [user,setUser] = useState({
    userName:"",
    userImage:"",
    userRole:""
  })
  const [permission,setPermission] = useState({
    item: { view: false, create: false, edit: false, delete: false },
    category: { view: false, create: false, edit: false, delete: false },
    purchase: { view: false, create: false, edit: false, delete: false },
    payment: { view: false, create: false, edit: false, delete: false },
    vendor: { view: false, create: false, edit: false, delete: false },
    user: { view: false, create: false, edit: false, delete: false },
    sales: { view: false, create: false, edit: false, delete: false },
    roleAndPermission: {
      view: false,
      create: false,
      edit: false,
      delete: false,
    },
  });

  const login = async (data) => {
    try {
      const res = await axios.post(`${config.BASE_URL}/user-login`, data);
      if (res.status === 200) {
        toast.success("Login Succesfull");
        localStorage.setItem('role',res.data.data.role);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem('userName', res.data.data.username);
        localStorage.setItem('userImage', res.data.data.profilePicture);
        localStorage.setItem('userRole', res.data.data.role);
        setUser({
          userName:res.data.data.username,
          userImage:res.data.data.profilePicture,
          userRole:res.data.data.role
        })
        const USERROLE = res.data.data.role;
        navigate("/dashboard");
        const resRole = await axios.get(`${config.BASE_URL}/getallroles`,{
          headers: {'Authorization': `Bearer ${res.data.token}`},
        });
        if (resRole.status === 200) {
          setRole(resRole.data.data);
          const matchingRole = resRole.data.data.find(item => item.role === USERROLE);
       setPermission(matchingRole.permissions);
       console.log(matchingRole.permissions);
        }
      }
    } catch (error) {
     toast.error("Email or Password is not correct")
      console.log(error.message);
    }
  };


  const loadUserData = () => {
    const storedToken = localStorage.getItem('token');
    const storedUserName = localStorage.getItem('userName');
    const storedUserImage = localStorage.getItem('userImage');
    const storedUserRole = localStorage.getItem('userRole');

    if (storedToken) {
      setUser({
        userName: storedUserName || "",
        userImage: storedUserImage || "",
        userRole: storedUserRole || ""
      });

      // Optionally, you can fetch roles again here if needed
      axios.get(`${config.BASE_URL}/getallroles`, {
        headers: { 'Authorization': `Bearer ${storedToken}` },
      })
        .then((res) => {
          if (res.status === 200) {
            setRole(res.data.data);
            const matchingRole = res.data.data.find(item => item.role === storedUserRole);
            setPermission(matchingRole.permissions);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  // Load user data on component mount
  useEffect(() => {
    loadUserData();
  }, []);

  const viewPermit = (data) =>{
    if(data === 'view'){
      
    }
  }

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ login, logout, role,user,permission }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
