import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CategoryContext } from '../Context/CategoryContext'
import { ItemContext } from '../Context/ItemContext';
import { PurchaseContext } from '../Context/PurchaseContext';
import { VendorContext } from '../Context/VendorContext';

const Dashboard = () => {
  const {getCategory} = useContext(CategoryContext);
  const {getItems} = useContext(ItemContext);
  const {getPurchases} = useContext(PurchaseContext);
  const {getVendors} = useContext(VendorContext);

  const dashData = {
    firstRow: 
      {
        TotalPurchases: 300,
        TotalStock: 100,
        TotalUsers: 50,
        TotalPayments: 50000
      },
    
    secondRow: {

    }


  }

  useEffect(()=>{
    getCategory();
    getItems();
    getPurchases();
    getVendors();
  },[])


  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          {/* Map Implementation |||||||||||||| */}
          <div className="col-lg-3 col-sm-6 col-12">
            <Link to='/purchase/reports' >
            <div className="dash-widget">
              <div className="dash-widgetimg">
                <span><img src="/img/icons/dash1.svg" alt="img" /></span>
              </div>
              <div className="dash-widgetcontent">
                <h5>$<span className="counters" data-count="307144.00">{dashData.firstRow.TotalPurchases}</span></h5>
                <h6>Total Purchase</h6>
              </div>
            </div>
            </Link>
          </div>


          <div className="col-lg-3 col-sm-6 col-12">
            <Link to='/payments/summary'>
            <div className="dash-widget dash1">
              <div className="dash-widgetimg">
                <span><img src="/img/icons/dash2.svg" alt="img" /></span>
              </div>
              <div className="dash-widgetcontent">
                <h5>$<span className="counters" data-count="4385.00">{dashData.firstRow.TotalPayments}</span></h5>
                <h6>Total Payments</h6>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <Link to='/stock/item-ledger'>
            <div className="dash-widget dash2">
              <div className="dash-widgetimg">
                <span><img src="/img/icons/dash3.svg" alt="img" /></span>
              </div>
              <div className="dash-widgetcontent">
                <h5><span className="counters" data-count="385656.50">{dashData.firstRow.TotalStock}</span></h5>
                <h6>Total Stock</h6>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-12">
            <Link to= '/users/list'>
            <div className="dash-widget dash3">
              <div className="dash-widgetimg">
                <span><img src="/img/icons/dash4.svg" alt="img" /></span>
              </div>
              <div className="dash-widgetcontent">
                <h5><span className="counters" data-count="40000.00">{dashData.firstRow.TotalUsers}</span></h5>
                <h6>Total Users</h6>
              </div>
            </div>
            </Link>
          </div>
         
        </div>

        <div className="row">
          <div className="col-lg-6 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Purchase & Sales</h5>
                <div className="graph-sets">
                  <ul>
                    <li>
                      <span>Sales</span>
                    </li>
                    <li>
                      <span>Purchase</span>
                    </li>
                  </ul>
                  <div className="dropdown">
                    <button className="btn btn-white btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      2022 <img src="/img/icons/dropdown.svg" alt="img" className="ms-2" />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <a href="#" className="dropdown-item">2022</a>
                      </li>
                      <li>
                        <a href="#" className="dropdown-item">2021</a>
                      </li>
                      <li>
                        <a href="#" className="dropdown-item">2020</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="sales_charts"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Purchase & Sales</h5>
                <div className="graph-sets">
                  <ul>
                    <li>
                      <span>Sales</span>
                    </li>
                    <li>
                      <span>Purchase</span>
                    </li>
                  </ul>
                  <div className="dropdown">
                    <button className="btn btn-white btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      2022 <img src="/img/icons/dropdown.svg" alt="img" className="ms-2" />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <a href="#" className="dropdown-item">2022</a>
                      </li>
                      <li>
                        <a href="#" className="dropdown-item">2021</a>
                      </li>
                      <li>
                        <a href="#" className="dropdown-item">2020</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="sales_charts"></div>
              </div>
            </div>
          </div>
          
        </div>

        <div className="col-lg-12 col-sm-12 col-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header pb-0 d-flex justify-content-between align-items-center">
                <h5 className="card-title mb-0">Purchase & Sales</h5>
                <div className="graph-sets">
                  <ul>
                    <li>
                      <span>Sales</span>
                    </li>
                    <li>
                      <span>Purchase</span>
                    </li>
                  </ul>
                  <div className="dropdown">
                    <button className="btn btn-white btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      2022 <img src="/img/icons/dropdown.svg" alt="img" className="ms-2" />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <a href="#" className="dropdown-item">2022</a>
                      </li>
                      <li>
                        <a href="#" className="dropdown-item">2021</a>
                      </li>
                      <li>
                        <a href="#" className="dropdown-item">2020</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div id="sales_charts"></div>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}

export default Dashboard